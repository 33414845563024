import { Center, Heading } from "@chakra-ui/react";
import React from "react";
import { Box, Text } from "@chakra-ui/react";
import PortfolioCard from "../components/Portfolio";
import api from "../api";
import { useAuth } from "../context/AuthContext";

const SmartBetScreen = () => {
    const [loading, setLoading] = React.useState(true);
    const { loadSubscriptions, getMyBets } = api;
    const [subscriptions, setSubscriptions] = React.useState([]);
    const [bets, setBets] = React.useState([{}]);
  
    const { user } = useAuth();
    React.useEffect(() => {
      const fetchSubscriptions = async () => {
        try {
          let response = await loadSubscriptions();
          setSubscriptions(response.data);
          response = await getMyBets();
          setBets(response.data);
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      };
      fetchSubscriptions();
    }, []);

    return (
        <Box w="100%" mx="auto" mt={3}>

        <Heading>
            Smart Best
        </Heading>
        <Text fontSize="xl">Here are yourt current live portfolios</Text>
        <Box>
            {subscriptions.map((subscription) => (
                <Center mb={5}>
                <PortfolioCard key={subscription.id} subscription={subscription} listGames={true} />
                </Center>
            ))}
        </Box>
        </Box>
    )
    
}

export default SmartBetScreen;