// screens/LoginScreen.js
import React, { useState } from 'react';
import api from '../api';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Alert,
  AlertIcon,
  Flex,
  Center,
  Image,
  useToast,
  Icon,
  IconButton
} from '@chakra-ui/react';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';

function SignUpScreen() {
  const { login } = useAuth();
  
  const [form, setForm] = useState({name:'', email:'',phone:'', password:'', confirm_password:''});
  const [error, setError] = useState(null);

  const toast = useToast();

  const { name, email, password, confirm_password } = form;
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!name || !email || !password || !confirm_password){
      setError('Please fill in all required fields');
      return;
    }
    if(password !== confirm_password){
        setError('Passwords do not match');
        return;
    }
    try {

      const response = await api.register(form);
      if(response.status === 201){  
        await login({ email, password });
        
      }
    } catch (err) {
      console.error(err); 
      setError(err.response.data.error);
      toast({
        title: "An error occurred.",
        description: err.response.data.error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" minH="100vh" style={{background:"url('football1.jpg')", backgroundSize:"cover"}}>

   <Box bg="yellow.100" py={4} style={{zIndex:'50'}}>
     <Center>
       <Image src="logo.png" alt="Logo" width={"300px"} />
     </Center>
   </Box>
   <Box maxW="md" mx="auto" mt={10} p={35} background={"gray.800"} borderRadius={10}>
   <Heading as="h1" size="xl" mb={6} textAlign="center">
        Account Log-In
      </Heading>
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
        <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              name='name'
              value={name}
              onChange={(e) => setField('name',e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name='email'
              value={email}
              onChange={(e) => setField('email',e.target.value)}
            />
            <IconButton icon={<FaEye/>}/>
          </FormControl>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              name='password'
              value={password}
              onChange={(e) => setField('password',e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Confirm Password</FormLabel>
            <Input
              type="password"
              name='confirm_password'
              value={confirm_password}
              onChange={(e) => setField('confirm_password',e.target.value)}
            />
          </FormControl>
          <Button type="submit" colorScheme="teal" size="lg" w="full">
            Sign Up
          </Button>
          <Link to={'/signin'}>Already have an account? Sign in here</Link>
        </VStack>
      </form>
    </Box>
    </Flex>
  );
}

export default SignUpScreen;
