import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  Text,
  Textarea,
  useToast,
  Avatar,
  VStack,
} from "@chakra-ui/react";
import api from "../api";
import { theme } from "../components/PrivateLayout";
import { useNavigate } from "react-router";
import Select from "react-select";
import Games from "./Admin/Games";

const BlackBox = ({ children }) => {
  return (
    <Box bg={theme.colors.bar_background} color="white" w={"full"} p={4} mt={4}>
      {children}
    </Box>
  );
};

const BlackSelect = ({ options, value, onChange }) => {
  return (
    <Select
      styles={{
        wrapper: (provided) => ({
          ...provided,
          width: "100%",
        }),
        container: (provided) => ({
          ...provided,
          width: "100%",
        }),
        control: (provided) => ({
          ...provided,
          backgroundColor: theme.colors.bar_background,
          color: "white",
          border: "1px solid white",
          borderRadius: "10px",
          width: "100%",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected
            ? theme.colors.primary
            : theme.colors.bar_background,
          color: "white",
        }),
      }}
      options={options}
      isMulti
      onChange={onChange}
    />
  );
};

const NewPortfolio = () => {
  const [portfolio, setPortfolio] = useState({
    name: "",
  });
  const [games, setGames] = useState([]);
  const [gamesParams, setGamesParams] = useState({});
  const [loadingGames, setLoadingGames] = useState(false);
  const [selectedCompetitions, setSelectedCompetitions] = useState([]);
  const [teams, setTeams] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [loading, setLoading] = useState(true);

  const toast = useToast();
  const navigate = useNavigate();

  const { createPortfolio, getCompetitions, getGameList } =
    api;

  
  const fetchCompetitions = async () => {
    try {
      const response = await getCompetitions();
      setCompetitions(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchGames = async () => {
    try {
      setLoadingGames(true);
      const params = {};
      
      if (selectedCompetitions.length > 0) {
        params.competition = selectedCompetitions
          .map((s) => s.value)
          .join(",");
      }
      const response = await getGameList(params);
      setGames(response.data);
    } catch (err) {
      console.error(err);
    }
    setLoadingGames(false);
  };

  const submitPortfolio = async () => {
    try {
      console.log(portfolio);
      if(portfolio.name === ''){
        toast({
            title: "Name is required",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return;
      }



      const Obj = {
        name: portfolio.name,
        sports: [],
        teams: [],
        competitions: selectedCompetitions.map((s) => s.value),
        geolocations: [],
      };
      const response = await createPortfolio(Obj);
      toast({
        title: "Portfolio created",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/portfolio/"+ response.data.id);
      
    } catch (err) {
      console.error(err);
    }
  }
    

  useEffect(() => {
    fetchCompetitions();
    setLoading(false);
  }, []);

  // useEffect(() => {
  //   fetchGames();
  // }, [selectedCompetitions]);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box w="100%" mx="auto" mt={10}>
      <Heading>Create a new portfolio</Heading>
      <VStack>
        <BlackBox>
          <Text>Name</Text>
          <Input
            value={portfolio.name}
            onChange={(e) =>
              setPortfolio({ ...portfolio, name: e.target.value })
            }
          />
        </BlackBox>
        <BlackBox>
          <Text>Competitions</Text>
          <HStack>
            <BlackSelect
              onChange={(e) => {
                console.log(e);
                setSelectedCompetitions(e);
              }}
              multiple
              options={competitions.map((s) => ({
                value: s.id,
                label: s.name + " - " + s.game_count + " games",
              }))}
            />
          </HStack>
        </BlackBox>
        <BlackBox>
            <Button onClick={submitPortfolio.bind(this)}>Create</Button>
        </BlackBox>
      </VStack>
      <Box w={'100%'} bg={theme.colors.bar_background} p={5} mt={10}>
    {/* <Heading>Games</Heading>
        <Button onClick={fetchGames}>Update</Button>
        <VStack>
          {loadingGames && <Text>Loading...</Text>}
          {!loadingGames && games.length === 0 && (
            <Text>No games found</Text>)}
          {!loadingGames && games.length > 0 && 
          <Text>{games.length} games found</Text>}
          {!loadingGames && false && games.map((game) => (
            <Box bg="gray.800" borderRadius="md" p={4} w="full">
              <Text color="whiteAlpha.700" fontSize="xs" mb={2}>
                {game.date}
              </Text>
              <Flex align="center" justify="space-between" mb={2}>
                <VStack w={`50%`}>
                  <Flex align="center">
                    <Avatar
                      w={10}
                      h={10}
                      fontSize={'sm'}
                      src={game.team_1.logo}
                      mr={2}
                      name={game.team_1.name}
                    />
                    <Text color="white" fontWeight="bold" fontSize={'sm'}>
                      {game.team_1.name}
                    </Text>
                  </Flex>
                </VStack>
                <VStack w={"50%"}>
                  <Flex align="center">
                    <Text color="white" fontWeight="bold"  fontSize={'sm'}>
                      {game.team_2.name}
                    </Text>
                    <Avatar

                    w={10}
                    h={10}
                      src={game.team_2.logo}
                      ml={2}
                      name={game.team_2.name}
                    />
                  </Flex>
                </VStack>
              </Flex>
            </Box>
          ))}
        </VStack> */}
      </Box>

    </Box>
  );
};

export default NewPortfolio;
