import React from "react";
import { Box, SimpleGrid, Heading, Switch, Button, FormControl, FormLabel, Input, Divider} from "@chakra-ui/react";
import api from "../api";
import { Form } from "react-router-dom";

const backgroundColors = ['orange.200', 'orange.300', 'orange.300'];


const CustomBox = ({ children, number }) => (
  <Box borderWidth="2px" borderRadius="lg" 
       overflow="hidden"  p={3} mb={2}
       borderColor={backgroundColors[0]}
       bg={backgroundColors[0]}
       borderStyle={'groove'}
       display={'flex'}
       flexDirection={'column'}
       >
    {children}
  </Box>
);

const FormField = ({ label,number=0, value, onChange = ()=>true, type, ...props}) => (
  <FormControl {...props} backgroundColor={backgroundColors[number%2]}>
    <SimpleGrid columns={2} p={2}>
      <FormLabel alignContent={'center'} fontSize={15}>{label}</FormLabel>
      <Input defaultValue={value} onChange={onChange} type={type} bg={'white'} />
    </SimpleGrid>
  </FormControl>
);
const Bookmarker = ({ bookmarker, index }) => {
  console.log(bookmarker);
  const [active, setActive] = React.useState(bookmarker.active);
  const { updateBookmarker } = api;

  const handleSwitch = async () => {
    try {
      const response = await updateBookmarker(bookmarker.id, !active);
      setActive(response.data.active);
    } catch (err) {
      console.error(err);
    }
  };


  return (
    <Box backgroundColor={backgroundColors[index%2]} borderWidth={'1px 1px 0px 1px'} borderColor={backgroundColors[1]}>
      <SimpleGrid columns={2} p={2} >
        <Box >{bookmarker.name}</Box>
        <Box style={{alignContent:'center'}}>
          <Switch onChange={handleSwitch} isChecked={active} size="md" />
        </Box>
      </SimpleGrid>
    </Box>
  );
}
const SettingsScreen = () => {
  const [user, setUser] = React.useState(null);
  const [filter, setFilter] = React.useState('');
  const [bookmarker, setBookmarker] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { getCurrentUser, getBookmarkers } = api;

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getCurrentUser();
        setUser(response.data);
      } catch (err) {
        console.error(err);
      }
      finally {
        setLoading(false);
      }
    };

    const fetchBookmarkers = async () => {
      try {
        const response = await getBookmarkers();
        setBookmarker(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchUser();
    fetchBookmarkers();
  }, []);

  return (
    <Box w={"100%"}>
      <Heading >Settings</Heading>
      {loading ? (
        <p>Loading...</p>
      ) : ( 
        <SimpleGrid columns={{ sm: 1, lg: 2 }} w={"100%"}>
          <CustomBox  >
            <Heading as="h2" size="md">
              User Information
            </Heading>
            <Divider borderColor={backgroundColors[1]} borderWidth={2} />
              <FormField borderColor={backgroundColors[1]} borderWidth={'1px 1px 1px 1px'} number={0} isDisabled label={'Email'} value={user.email} type={'text'} onChange={() => true}/>
              <FormField borderColor={backgroundColors[1]} borderWidth={'1px 1px 1px 1px'} number={1} isDisabled label={'Name'} value={user.name} type={'text'} onChange={() => true}/>
              <FormField borderColor={backgroundColors[1]} borderWidth={'1px 1px 1px 1px'} number={2} label={'Username'} value={user.username} type={'text'} onChange={() => true}/>
              <Button ml={'auto'} colorScheme="orange" size="sm" mt={2}>Update</Button>
          </CustomBox>
          <CustomBox >
            <Box>
              <Heading size={'md'}>Bookmarkers:</Heading>
              <Divider borderColor={backgroundColors[1]} borderWidth={2} />
              <FormField bg={backgroundColors[2]} number={0} label={'Filter:'} value={''} type={'text'} onChange={(evt) => setFilter(evt.target.value)}/>
              <SimpleGrid columns={3} py={2}>
                {bookmarker.length === 0 && <Box>Loading...</Box>}
                {bookmarker.length && bookmarker.filter(b=> b.name.search(filter)!==-1).sort((a,b)=> a.name.localeCompare(b.name)).map((bookmarker, index) => (
                  <Bookmarker bookmarker={bookmarker} index={index}/>
                ))}
              </SimpleGrid>
            </Box>
          </CustomBox>
        </SimpleGrid>
      )}
    </Box>
  );
};

export default SettingsScreen;
