import React, { useState } from 'react';
import { Button, Modal, ModalOverlay, Text,ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, Textarea, useDisclosure } from '@chakra-ui/react';
import { useAuth } from '../context/AuthContext'; // Adjust the import path
import { useLocation } from 'react-router-dom';
import { FaStopCircle } from 'react-icons/fa';
import { theme } from './PrivateLayout';
import api from '../api';

const ReportButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null); // State to hold the uploaded image
  const { user } = useAuth(); // Assuming useAuth returns an object with user info
  const location = useLocation(); // Hook to get the current route
  const {putReport} = api;

  const handleImageUpload = (e) => {
    setImage(e.target.files[0]); // Store the selected image file
  };

  const handleSubmit = async () => {
    const reportData = new FormData();
    reportData.append('title', title);
    reportData.append('description', description);
    reportData.append('dump',JSON.stringify({ location:location.pathname, user:user})); // Current route
    if (image) {
      reportData.append('image', image); // Append the image file if it exists
    } 

    // Send reportData to your backend or desired endpoint
    await putReport(reportData)


    // Clear form and close modal
    setTitle('');
    setDescription('');
    setImage(null);
    onClose();
  };

  return (
    <>
      <Button
        onClick={onOpen}
        position="fixed"
        bottom="20px"
        right="20px"
        colorScheme="red"
        size="xs"
        borderRadius="full"
        zIndex={1000}
      >
        <FaStopCircle />
      </Button>

      <Modal  isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={theme.colors.bar_background}>
          <ModalHeader>Report a Bug or Improvement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              mb={4}
            />
            <Textarea
              placeholder="Describe the issue or improvement"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              mb={4}
            />
            <Text mb={2}>Attach a screenshot (optional)</Text>
            <Input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              mb={4}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleSubmit}>
              Submit
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReportButton;
