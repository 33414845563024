import React, { useEffect, useState } from "react";
import { Input } from "@chakra-ui/react";

const formatCurrency = (value) => {
    if (!value) return "";
    const number = parseFloat(value);
    if (isNaN(number)) return "";
    
    // Format the number as currency
    const options = {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    const formatted = (number / 100).toLocaleString("en-US", options);
    return formatted;
  };

const MoneyInput = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState(formatCurrency(value*100));
  
  const handleChange = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, ""); // Remove all non-digit characters
    const formattedValue = formatCurrency(numericValue);
    setInputValue(formattedValue);
    onChange(numericValue/100);
  };

  useEffect(() => {
    setInputValue(formatCurrency(value*100));
  }, [value]);

  const handleKeyDown = (event) => {
    const { key } = event;
    if (key === "Backspace" || key === "Delete") return; // Allow backspace and delete

    if (isNaN(parseInt(key))) {
      event.preventDefault(); // Prevent non-numeric keys
    }
  };

  return (
    <Input
      type="text"
      value={inputValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder="$0.00"
    />
  );
};

export default MoneyInput;
