// components/PrivateLayout.js
import React from 'react';
import { Box, Flex, IconButton, Text, VStack, Spacer, Center, Image, Button, background } from '@chakra-ui/react';
import { FaHome, FaUser, FaCog, FaMoneyBill } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import SideMenu from '../components/SideMenu';


export const theme = {
  colors: {
    background: '#181818',
    bar_background: '#231F20',
    primary: '#FFFFFF',
    secondary: '#FFFFFFC0',
    
  }
}

const PrivateLayout = ({ children }) => {
  return (
    <Flex direction="column" minH="100vh">
      {/* Header */}
      <Box bg={theme.colors.bar_background} py={4}>
        <Center>
          <Image src="/Logo.png" alt="Logo" width={'300px'} />

          <SideMenu />
        </Center>
      </Box>

      {/* Main Content */}
      <Flex flex="1" direction="column" p={4} mb={20} w={"100%"} bg={theme.colors.background}>
        {children}
      </Flex>

      {/* Bottom Menu */}
      <Box bg={theme.colors.bar_background} borderTop={'1px solid '+theme.colors.background} py={2} style={{position:'fixed',bottom:'0', width:'100%'}}>
        <Flex justify="space-around">
          <VStack as={Link} to="/dashboard" >
            <IconButton
              variant="ghost"
              colorScheme="whiteAlpha"
              icon={<FaHome />}
              textColor={'white'}
              aria-label="Home"
            />
            <Text color="white" fontSize="sm">Home</Text>
          </VStack>
          <VStack as={Link} to="/account">
            <IconButton
              variant="ghost"
              colorScheme="whiteAlpha"
              icon={<FaUser />}
              textColor={'white'}
              aria-label="Account"
            />
            <Text color="white" fontSize="sm">Account</Text>
          </VStack>
          <VStack as={Link} to="/bets">
            <IconButton
              variant="ghost"
              colorScheme="whiteAlpha"
              icon={<FaMoneyBill />}
              textColor={'white'}
              aria-label="Portfolios"
            />
            <Text color="white" fontSize="sm">Smart Bets</Text>
          </VStack>
          <VStack as={Link} to="/portfolios">
            <IconButton
              variant="ghost"
              colorScheme="whiteAlpha"
              icon={<FaCog />}
              textColor={'white'}
              aria-label="Portfolios"
            />
            <Text color="white" fontSize="sm">Portfolios</Text>
          </VStack>
        </Flex>
      </Box>
    </Flex>
  );
};

export default PrivateLayout;
