// context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../api'; // Adjust the path according to your project structure
import { useNavigate } from 'react-router-dom';

// Create the AuthContext
const AuthContext = createContext();

// AuthProvider component to provide the context to its children
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there's a token in local storage when the component mounts
    const token = localStorage.getItem('token');
    if (token) {
      api.getCurrentUser().then(response => {
        setUser(response.data);
        setLoading(false);
      }).catch((e) => {
        console.log(e)
        localStorage.removeItem('token');
        localStorage.removeItem('refresh');
        setLoading(false);
      
      });
    } else {
      setLoading(false);
    }
  }, []);

  const login = async (data) => {
    try {
      const response = await api.login(data);
      localStorage.setItem('token', response.data.access);
      localStorage.setItem('refresh', response.data.refresh);
      const userResponse = await api.getCurrentUser();
      setUser(userResponse.data);
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };


  

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    setUser(null);
    console.log('Logout');
    window.location.href = '/login';
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      <div>{loading ? 'Loading...' : ''}</div>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
