import React from "react";
import {
    HStack,
    Divider,
    Text,
    Input,
    InputGroup,
    InputLeftElement,
    Button,
    Box,
    Heading,
    Avatar,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,

    useToast,

    VStack,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import api from "../api";
import moment from "moment";
import BetModal from "../components/BetModal";
import MoneyInput from "../components/MoneyInput";
import BetCard from "../components/BetCard";

// Utility functions
export const calculateBestOdds = (games) => {
    const bestOdd = {};
    if (games && games.length > 0) {
        games.forEach(game => {
            const bet_recommendation = game.game.game_statistics[0].bet_reccomendation;
            const gb = game.game.game_bookmarkers?.toSorted((a, b) => {
                if (bet_recommendation === 1) {
                    return a.team_1_odd - b.team_1_odd;
                }
                if (bet_recommendation === -1) {
                    return a.team_2_odd - b.team_2_odd;
                }
                return a.draw_odd - b.draw_odd;
            }).reverse();
            bestOdd[game.game.id] = {
                odd: bet_recommendation === 1 ? gb[0].team_1_odd : bet_recommendation === -1 ? gb[0].team_2_odd : gb[0].draw_odd,
                ...gb[0]
            };
        });
    }
    return bestOdd;
};

// Components
// const BetCard = ({ gamebet, betValue, bestOdd }) => {
//     const game = gamebet.game;
//     const stats = gamebet.game.game_statistics[0];
//     const bet = {
//         1: game.team_1.name,
//         0: "Draw",
//         "-1": game.team_2.name,
//     };
//     const bet_recommendation = bet[stats.bet_reccomendation];
//     return (
//         <Card key={game.id} backgroundColor={"orange.400"}>
//             <CardHeader>
//                 <SimpleGrid columns={2} spacing={4}>
//                     <VStack>
//                         <Avatar name={game.team_1.name} />
//                         <Heading size="sm">{game.team_1.name}</Heading>
//                     </VStack>
//                     <VStack>
//                         <Avatar name={game.team_2.name} />
//                         <Heading size="sm">{game.team_2.name}</Heading>
//                     </VStack>
//                 </SimpleGrid>
//                 <Heading size="sm" textAlign={'center'}>
//                     <small>{game.competition.name} - {moment(game.date).format('MMMM Do YYYY, h:mm a')}</small>
//                 </Heading>
//             </CardHeader>
//             <CardBody px={0}>
//                 <TableContainer
//                     columnGap={0}
//                     overflowX={"none"}
//                     whiteSpace={"wrap"}
//                     borderRadius={"sm"}
//                     mx={2}
//                     mb={2}
//                 >
//                     <Table variant="striped" w={"100%"} colorScheme="orange">
//                         <Tbody>
//                             <Tr>
//                                 <Td>Team 1</Td>
//                                 <Td>{game.team_1.name}</Td>
//                             </Tr>
//                             <Tr>
//                                 <Td>Team 2</Td>
//                                 <Td>{game.team_2.name}</Td>
//                             </Tr>
//                             <Tr>
//                                 <Td>Date</Td>
//                                 <Td>{moment(game.date).format("YYYY-MM-DD")}</Td>
//                             </Tr>
//                             <Tr>
//                                 <Td>Bet on</Td>
//                                 <Td>{bet_recommendation}</Td>
//                             </Tr>
//                             <Tr>
//                                 <Td>Pot Percentage</Td>
//                                 <Td>{(gamebet.weight * 100).toFixed(2)}%</Td>
//                             </Tr>
//                             <Tr>
//                                 <Td>Best Odd</Td>
//                                 <Td>{bestOdd[game.id]?.odd}</Td>
//                             </Tr>
//                             <Tr>
//                                 <Td>Bookmaker</Td>
//                                 <Td>{bestOdd[game.id]?.bookmarker?.name}</Td>
//                             </Tr>
//                         </Tbody>
//                     </Table>
//                 </TableContainer>
//             </CardBody>
//             <CardFooter>
//                 <Heading size="sm">
//                     Suggested Value: <Text color="green.900">${(gamebet.weight * betValue).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Text>
//                 </Heading>
//             </CardFooter>
//         </Card>
//     );
// };

const BetPage = () => {
    const [loading, setLoading] = React.useState(true);
    const [portfolio, setPortfolio] = React.useState(null);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [games, setGames] = React.useState(null);
    const [betObj, setBet] = React.useState(null);
    const [betValue, setBetValue] = React.useState(100);
    const { getPortfolio, loadBets, bet, getPortfolioBet } = api;
    const { portfolioId } = useParams();
    const toast = useToast();
    const getBet = async () => {
        try {
            const response = await getPortfolioBet(portfolioId);
            if (response.data && response.data.length === 0) {
                return;
            }
            setBet(response.data);
            console.log(response.data);
            setBetValue(response.data.value);
        } catch (err) {
            console.error(err);
        }
    };
    React.useEffect(() => {

        const fetchPortfolio = async () => {
            try {
                let response = await getPortfolio(portfolioId);
                setPortfolio(response.data);
                response = await loadBets(portfolioId);
                if (response.data.length === 0) {
                    return;
                }
                setGames(response.data);
                getBet();
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        

        fetchPortfolio();
    }, [portfolioId, getPortfolio, loadBets, getPortfolioBet]);

    const bestOdd = React.useMemo(() => calculateBestOdds(games), [games]);
    const games_with_values = React.useMemo(() => {
        const newGames = [];
        let total = 0;
        if (!games) {
            return [];
        }

        for (const game of games.sort((a, b) => b.weight - a.weight)) {
            console.log('betValue', betValue);
            console.log('game.bet_value', game);
            let value = game.weight * betValue;
            console.log(value);
            value = Math.round(value/5)*5;
            console.log(value);
            if (total + value > betValue) {
                value = betValue - total;
                if(value%5 !== 0){
                    value = Math.round(value/5)*5;
                }
            }
            total += value;
            newGames.push({
                ...game,
                value: value,
            });
        }
        return newGames;
    }, [games, betValue]);
    const openModal = () => {
        setModalOpen(true);
    }
    const closeModal = () => {
        setModalOpen(false);
        getBet();
    }

    if (loading || portfolio === null) {
        return <Text>Loading...</Text>;
    }

    return (
        <Box maxW="100%" mx="auto" mt={10} w={"100%"}>
            <BetModal portfolio={portfolio} games={games_with_values} total={betValue} open={modalOpen} setModalOpen={closeModal} />
            <Heading as="h1" size="xl" mb={6} textAlign="left">
                Portfolio {portfolio.name}
            </Heading>
            <HStack spacing={4} mb={2} align="start">
                <Text>Your total:</Text>
                <InputGroup>
                <NumberInput value={betValue} onChange={setBetValue} step={5}> 
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                    </NumberInput>

                </InputGroup>
                <Text color={'green'}>Suggested Bet Value: <b>${games_with_values.reduce((total,c) => total + parseFloat(c.value), 0)}</b></Text>

            </HStack>
            <HStack>
                    <Button onClick={() => setBetValue(Number(betValue)+10)}>$10</Button>
                    <Button onClick={() => setBetValue(Number(betValue)+50)}>$50</Button>    
                    <Button onClick={() => setBetValue(Number(betValue)+100)}>$100</Button>
                    <Button onClick={() => setBetValue(Number(betValue)-10)}>-$10</Button>
                    <Button onClick={() => setBetValue(Number(betValue)-50)}>-$50</Button>
                    <Button onClick={() => setBetValue(Number(betValue)-100)}>-$100</Button>
                </HStack>
            <Button size={'lg'} mt={5} w={'200px'} colorScheme="red" onClick={openModal}>Bet</Button>
            <Divider my={2} />
            <VStack>
                {!games && <Text>Loading...</Text>}
                {games_with_values && games_with_values.map((gamebet) => (
                    <BetCard key={gamebet.game.id} gamebet={gamebet} betValue={betValue} bestOdd={bestOdd} />
                ))}
            </VStack>
        </Box>
    );
};

export default BetPage;
