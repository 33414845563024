// components/PrivateLayout.js
import React from 'react';
import { Box, Flex, IconButton, Text, VStack, Spacer, Center, Image, Button, Grid, GridItem } from '@chakra-ui/react';
import { FaHome, FaUser, FaCog, FaFootballBall} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {AdminSideMenu} from './SideMenu';

const AdminLayout = ({ children }) => {
  return (
    <Flex direction="row" minW="100vw">
      {/* Header */}
      <Box bg="orange.400" py={4} h={"100vh"} w={"150px"}>
        <Center>
          <Image src="/logo.png" alt="Logo" width={'300px'} />
        </Center>
        <Button as={Link} to="/admin" colorScheme="orange" w={`100%`} value="Home" aria-label="Home" borderRadius={0} borderBottom={"2px solid gray.900"}>
          <FaHome /> Home
        </Button>

        <Button as={Link} to="/admin/games" colorScheme="orange" w={`100%`} value="Settings" aria-label="Settings" borderRadius={0} borderBottom={"2px solid gray.900"}>
          <FaFootballBall/> Games
        </Button>
      </Box>

      {/* Main Content */}
      <Flex flex="1" direction="column" p={4} mb={20} h={"100vh"} bg={'orange.300'}>
 
        
                {children}

      </Flex>


    </Flex>
  );
};

export default AdminLayout;
