import {
  Box,
  Tab,
  TableContainer,
  Table,
  Td,
  Tr,
  Thead,
  Th,
  Tbody,
  Card,
  CardBody,
  Heading,
  SimpleGrid,
  Select,
} from "@chakra-ui/react";
import React from "react";
import api from "../../api";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import moment from "moment";


const GamesDataGrid = ({ games }) => {
  const columns = [
    {
      name: "id",
      header: "ID",
      width: 50,
    },
    {
      name: "team_1",
      header: "Team 1",
    },
    {
      name: "team_2",
      header: "Team 2",
    },
    {
      name: "date",
      header: "Start Time",
    },
    {
        name: 'bet',
        header: 'Bet',
        width: 100,
    },
    {
      name: "result",
      header: "Result",
      
    },
    {
      name: "can_draw",
      header: "Can Draw",
      width: 50,

    },
    {
      name: "sport",
      header: "Sport",
    },
    {
        name: "competition",
        header: "Competition",
    },
    
    {
        name: 'pcm',
        header: 'PCM',
        width: 100,
    },
    {
        name: 'nce',
        header: 'NCE Game',
        width: 100,
    },


  ];

  const mappedGames = games.map((game) => {
    const game_statistics = game.game_statistics[0]
    let bet = ''
    let result = ''
    let pcm = 0
    let nce = 0
    if (game_statistics) {
        bet = game_statistics.bet_reccomendation == -1? game.team_2.name : game_statistics.bet_reccomendation == 0? 'Draw' : game.team_1.name
        
        pcm = game_statistics.pcm_game
        nce = game_statistics.nce_game    
    }
    if(!isNaN(game.result) && game.result != null){
        result = game.result == 1? game.team_1.name : game.result == 0? 'Draw' : game.team_2.name
    }
    return {
      id: game.id,
      team_1: game.team_1.name,
      team_2: game.team_2.name,
      date: moment(game.date).format('YYYY-MM-DD HH:mm'),
      result: result,
      can_draw: game.can_draw? 'Yes' : 'No',
      sport: game.sport.name,
      competition: game.competition.name,
      bet, pcm, nce
    };
  })
  return <ReactDataGrid
  idProperty="id"
  columns={columns}
  dataSource={mappedGames}
  style={{ minHeight: 500 }}
/>
};

const Games = () => {
  const [games, setGames] = React.useState([]);
  const [sports, setSports] = React.useState([]);
  const [competitions, setCompetitions] = React.useState([]);
  const [teams, setTeams] = React.useState([]);
  const [filters, setFilters] = React.useState({
    sport: [],
    competition: [],
    team: []
  });
  
  const selectFilter = (filter, value) => {
    if(value == 'all'){
      value = []
    } else {
      value = [value]
    }
    setFilters({...filters, [filter]: value})
  }


  const [loading, setLoading] = React.useState(true);

  const { getAdminGames, putAdminGame } = api;

  const fetchGames = async () => {
    try {
       const params = {
        limit: 100
       }
      for (const key in filters) {
        if (filters[key].length > 0) {
          params[key] = filters[key].join(',');
        }
      }


      const response = await getAdminGames(params);
      setGames(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchFilters = async () => {
    try {
      const response = await api.getAdminGamesFilters();
      setSports(response.data.sports);
      setCompetitions(response.data.competitions);
      setTeams(response.data.teams);
    } catch (err) {
      console.error(err);
    }
  }

  React.useEffect(() => {
    fetchGames();
    fetchFilters();
  }, []);
  
  React.useEffect(() => {
    if(!loading){
      setLoading(true);
      fetchGames().finally(() => setLoading(false));
    }
  }
  , [filters]);

  const updateGame = async (game) => {
    try {
      await putAdminGame(game);
      fetchGames();
    } catch (err) {
      console.error(err);
    }
  };
  

  return (
    <Box w={"100%"} h={'100%'}>
      <Heading as="h2" size="xl" mb={6} textAlign="center">
        Games
      </Heading>
      <SimpleGrid columns={5} spacing={10}>
        <Select placeholder="Select Sport" disabled={loading} onChange={(e) => selectFilter('sport',e.target.value) }>
          <option value="all">All</option>
          {sports.map((sport) => (
            <option key={sport.id} value={sport.id}>
              {sport.name}
            </option>
          ))}
        </Select>
        <Select placeholder="Select Competition" 
          disabled={loading}
          onChange={(e) => selectFilter('competition',e.target.value) }>
          <option value="all">All</option>
          {competitions.map((competition) => (
            <option key={competition.id} value={competition.id}>
              {competition.name}
            </option>
          ))}
        </Select>
        <Select placeholder="Select Team" disabled={loading} onChange={(e) =>  selectFilter('team',e.target.value)}>
          <option value="all">All</option>
          {teams.map((team) => (
            <option key={team.id} value={team.id}>
              {team.name}
            </option>
          ))}
        </Select>
      </SimpleGrid>
      {loading && <p>Loading...</p>}
     {!loading && <GamesDataGrid games={games} />}
    </Box>
  );
};

export default Games;