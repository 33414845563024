import React from "react";
import { Box, Heading, VStack, Center, HStack, Button, useToast } from "@chakra-ui/react";
import api from "../api";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Link } from "react-router-dom";
import { FaChevronLeft, FaPlus, FaTrash } from "react-icons/fa";
import { IconButton, Text } from "@chakra-ui/react";
import { theme } from "../components/PrivateLayout";



const TextComponent = ({ label, value }) => {
    return (
        <Box w={'full'} p={5}
             
            borderRadius={5}
            background={theme.colors.bar_background}>
            <HStack>
                <Text fontWeight={'bold'} fontSize={'md'}>{label}</Text>
                <Text ml="auto" fontSize={'md'}>{value}</Text> 
            </HStack>
        </Box>)
}


const PortfolioScreen = () => {
    const {portfolioId} = useParams();
    const [portfolio, setPortfolio] = React.useState([]);
    const { getPortfolio, subscribe} = api;
    const { user } = useAuth();
    const [loading, setLoading] = React.useState(true);
const toast = useToast();


    const fetchPortfolio = async () => {
        try {
            const response = await getPortfolio(portfolioId);
            setPortfolio(response.data);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }; 

    const handleUnsubscribe = async () => {
        
    }
    const handleSubscribe = async () => {
        try {
            await subscribe(portfolioId);
            toast({
                title: "Subscribed",
                description: "You have successfully subscribed to this portfolio",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            fetchPortfolio();
        } catch (err) {
            console.error(err);
        }
    }

    React.useEffect(() => {
        
        fetchPortfolio();
    }, []);

    if (loading) {
        return <Center>Loading...</Center>;
    }

    const isSubscribed = !!portfolio.portfolio_users.id

    return (
        <Box w={'full'}>
            <HStack mb={10}>
            <Link to="/portfolios">
                <IconButton icon={<FaChevronLeft />} color={'white'} background="transparent" />
            </Link>
            <Heading >{portfolio.name}</Heading>
            { isSubscribed &&
                <Button variant={'ghost'} colorScheme={'red'}
                 ml={'auto'}  >
                   <FaTrash/>&nbsp;Remove
                </Button>
            }
            { !isSubscribed &&
                <Button variant={'ghost'} colorScheme={'green'}
                 ml={'auto'}  onClick={handleSubscribe}>
                   <FaPlus/>&nbsp;Subscribe
                </Button>
            }
            </HStack>
            <VStack align="start">
                <TextComponent label={'Name'} value={portfolio.name} />

                <TextComponent label={'Sports'} value={portfolio.sports.map(s => s.name).join(',')} />

                {portfolio.competitions.length>0 && <TextComponent label={'Competitions'} value={portfolio.competitions.map(c => c.name).join(',')} />}

                {portfolio.teams.length>0 && <TextComponent label={'Teams'} value={portfolio.teams.map(t => t.name).join(',')} />}
                {portfolio.geolocations.length>0 && <TextComponent label={'Geolocations'} value={portfolio.geolocations.map(g => g.name).join(',')} />}
                
                <TextComponent label={'Max Bet Per Portfolio'} value={6} />

                <TextComponent label={'PCE Threshold'} value={"12,5%"} />

                <TextComponent label={'NSE Limit'} value={"4%"} />
            </VStack>
        </Box>
    );
}

    export default PortfolioScreen;