// screens/DashboardScreen.js
import React, { useContext } from "react";
import { theme } from "../components/PrivateLayout";
import moment from "moment";
import {
  Box,
  Heading,
  VStack,
  Center,
  HStack,
  Button,
  Flex,
  Text,
  Avatar,
  IconButton,
  Tag,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import api from "../api";
import { useAuth } from "../context/AuthContext";
import { FaArrowLeft, FaChevronRight, FaPlus } from "react-icons/fa";
import PortfolioCard from "../components/Portfolio";
import PerformanceCard from "../components/Performance";

const calculateNextMatch = (games) => {
  let nextMatch = null;
  const nextMatchs = games.filter((game) => {
    return moment(game.game.date).isAfter(moment());
  });
  if (nextMatchs.length === 0) {
    return null;
  }
  nextMatch = nextMatchs[0];
  for (let i = 0; i < nextMatchs.length; i++) {
    if (moment(nextMatchs[i].game.date).isBefore(moment(nextMatch.game.date))) {
      nextMatch = nextMatchs[i];
    }
  }
  return nextMatch;
};

const Subscription = ({ subscription }) => {
  const [games, setGames] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [betObj, setBet] = React.useState(null);
  const [countdownInterval, setCountdownInterval] = React.useState(null);
  const { loadBets, getPortfolioBet } = api;
  const [countDown, setCountDown] = React.useState(null);
  const getBet = async () => {
    try {
      const response = await getPortfolioBet(subscription.id);
      if (response.data && response.data.length === 0) {
        return;
      }
      setBet(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const unsubscribe = async (e) => {
    try {
      e.preventDefault();
      await api.unsubscribe(subscription.id);
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  const getCountDown = (date) => {
    const now = moment();
    const gameDate = moment(date);
    const diff = gameDate.diff(now, "seconds");
    if (diff < 0) {
      return "00:00:00";
    }
    if (diff > 24 * 60 * 60 ) {
      return gameDate.fromNow();
    }
    console.log(diff);
    const hours = Math.floor(diff / (60 * 60));
    const minutes = Math.floor((diff % (60 * 60)) / 60);
    const seconds = Math.floor((diff % 60) / 1);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  React.useEffect(() => {
    const fetchPortfolio = async () => {
      try {
        const response = await loadBets(subscription.id);
        if (response.data.length === 0) {
          return;
        }
        setGames(response.data);
        getBet();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchPortfolio();
  }, []);

  React.useEffect(() => {
    if (games && !countdownInterval) {
      const interval = setInterval(() => {
        const nextMatch = calculateNextMatch(games);
        if(!nextMatch){
          return;
        }
        console.log()
        setCountDown(getCountDown(nextMatch.game.date));
        setCountdownInterval(interval);
      }, 1000);
    }
  });

  if (!games || games.length === 0) {
    return null;
  }

  const nextMatch = calculateNextMatch(games);
  const diffHours = nextMatch ? moment(nextMatch.game.date).diff(moment(), `hours`) : Math.infinity;
  return (
    <Box
      bg="gray.800" // Assuming this is the background color from the theme
      order={ diffHours }
      borderLeft={`5px solid ${
        diffHours>24
          ? theme.colors.primary
          : "#FF0000"
      }`}
      borderRadius="md"
      p={4}
      w="100%" // Adjust width as needed
    >
      <Flex justify="space-between" align="center">
        <Flex align="center">
          <Text color="white" fontWeight="bold" fontSize="lg">
            {subscription.name} <Button onClick={unsubscribe} colorScheme="red" size="sm">Unsubscribe</Button>
          </Text>
        </Flex>
        <Text color="white" fontSize="sm">
          {Number(games && games.length)}/
          {subscription.portfolio_users.game_number}
        </Text>
      </Flex>
      <Text mt={2} color="whiteAlpha.700" fontSize="sm">
        {nextMatch && "Next match starts in"}
        {!nextMatch && "No upcoming matches"}
      </Text>
      <Text
        color={
          diffHours < 24
            ? "red.500"
            : "green.500"
        }
        fontWeight="bold"
        fontSize="xl"
        mt={1}
      >
        {countDown} 
      </Text>
    </Box>
  );
};

function DashboardScreen() {
  const [loading, setLoading] = React.useState(true);
  const { loadSubscriptions, getMyBets } = api;
  const [subscriptions, setSubscriptions] = React.useState([]);
  const [bets, setBets] = React.useState([{}]);

  const { user } = useAuth();
  React.useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        let response = await loadSubscriptions();
        setSubscriptions(response.data);
        response = await getMyBets();
        setBets(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchSubscriptions();
  }, []);
  return (
    <Box w="100%" mx="auto" mt={3}>
      <Heading
        as="h1"
        size="xl"
        color={theme.colors.primary}
        mb={6}
        textAlign="left"
      >
        Dashboard
      </Heading>
      <HStack w={"100%"} spacing="10px" marginBottom={`50px`}>
        <Avatar
          size="lg"
          background="teal.200"
          name={user.name}
          src={user.photo}
        />
        <VStack>
          <Text fontSize="xl">{user.name}</Text>
          <Tag colorScheme="yellow">Gold</Tag>
        </VStack>
        <IconButton
          marginLeft={`auto`}
          background={`transparent`}
          icon={<FaChevronRight />}
          className="secondary"
          as={Link}
          to="/dashboard"
        />
      </HStack>
      <Heading
        as="h2"
        size="lg"
        color={theme.colors.primary}
        mb={6}
        textAlign="left"
      >
        Smart Bets
      </Heading>
      <Box w={"100%"} marginBottom={"50px"}>
        {loading && <Text>Loading...</Text>}
        <VStack>
          {" "}
          {!loading && subscriptions.length == 0 && (
            <Center>
              <Button border={'1px solid white'} colorScheme="black"   as={Link} to="/portfolios">
                Subscribe to a portfolio to start betting &nbsp; <FaPlus />
              </Button>
            </Center>
          )}
          {!loading &&
            subscriptions.map((subscription) => (
              <Link to={`/bets/${subscription.id}`} style={{width: '100%'}} >
              <Subscription subscription={subscription} />
              </Link>
            ))}
        </VStack>
      </Box>
      <Heading
        as="h2"
        size="lg"
        color={theme.colors.primary}
        mb={6}
        textAlign="left"
      >
        Portfolios
        <Text fontSize={`12px`} color="blue.500">
          <Link>View All</Link>
        </Text>
      </Heading>
      <Box overflowX="auto" whiteSpace="nowrap" pb={4} mb={5}>
        <Flex>
          {subscriptions.map((subscription, index) => (
            <PortfolioCard key={index} subscription={subscription} /> 
          ))}
        </Flex>
      </Box>
      <Heading
        as="h2"
        size="lg"
        color={theme.colors.primary}
        mb={6}
        textAlign="left"
      >
        Performance
      </Heading>
      {/*<PerformanceCard
        metricValue={0}
        percentageChange={0}
        chartData={{
          labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          datasets: [
            {
              data: [100, 200, 300, 400, 450, 500, 550],
              borderColor: "#00ff00",
              borderWidth: 2,
              fill: false,
              tension: 0.4, // for smooth curve
              pointRadius: 0,
            },
          ],
        }}
      />*/}
    </Box>
  );
}

export default DashboardScreen;
