import React from "react";
import {
  Button,
  Drawer,
  IconButton,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  VStack,
  Link as ChakraLink,
  Avatar,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaBars, FaCog, FaDoorOpen, FaHome, FaUser } from "react-icons/fa";
import { theme } from "./PrivateLayout";

const MenuButton = ({ value, icon, route }) => {
  return (
    <Button
      as={Link}
      to={route}
      colorScheme="gray"
      w={`100%`}
      value={value}
      aria-label={value}
      borderRadius={0}
      borderBottom={"2px solid gray.900"}
    >
      {icon} {value}
    </Button>
  );
};

function SideMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
 
        <Avatar style={{ position: "absolute", right: "20px" }} onClick={onOpen}/>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bg={theme.colors.bar_background}>
          <DrawerCloseButton />
          <DrawerHeader>IBet</DrawerHeader>
          <VStack spacing={1} bg={theme.colors.bar_background}>
            <MenuButton value="Logout" icon={<FaDoorOpen />} route="/logout" />
          </VStack>
          <DrawerFooter >
            <Button colorScheme="black" variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export const AdminSideMenu = () => {
  return (
    <Drawer isOpen={true} placement="left" w={"150px"}>
      <DrawerContent bg="orange.400">
        <VStack spacing={1} bg="orange.400" h={"fill"}>
          <MenuButton value="Home" icon={<FaHome />} route="/admin" />
          <MenuButton value="Users" icon={<FaUser />} route="/admin/users" />
          <MenuButton
            value="Settings"
            icon={<FaCog />}
            route="/admin/settings"
          />
        </VStack>
      </DrawerContent>
    </Drawer>
  );
};
export default SideMenu;
