import React from "react";
import {
  Box,
  Button,
  Card,
  Image,
  Center,
  Flex,
  VStack,
    Heading,
    Highlight,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const HomeScreen = () => {
  return (
    <Flex direction="column" minH="100vh" style={{background:"url('football1.jpg')", backgroundSize:"cover"}}>
       <Box bg="rgba(60,60,60,0.4)" position={"fixed"} h={'100%'} w={'100%'} py={4} />

      <Box bg="yellow.100" py={4} style={{zIndex:'50'}}>
        <Center>
          <Image src="logo.png" alt="Logo" width={"300px"} />
        </Center>
      </Box>

      <Box  margin={'auto'} marginRight={'10%'} style={{zIndex:'50'}}>
        <VStack  >
        <Heading lineHeight='tall' color={'white'} width={'250px'}>
            <Highlight
                query='consistent'
                styles={{ px: '2', py: '1', rounded: 'full', bg: 'red.300' }}
            >
                Data driven smarter betting for consistent wins
            </Highlight>
        </Heading>
          <Link to={"login"} style={{width: "100%" }}> 
            {
              <Button
                style={{ zIndex: 100, margin: "auto", width: "100%" }}
                colorScheme="blue"
              >
                Login
              </Button>
            }{" "}
          </Link>
          <Link to={"signup"} style={{width: "100%" }}>
            {
              <Button
                style={{ zIndex: 100, margin: "auto", width: "100%" }}
                colorScheme="blue"
              >
                Create Account
              </Button>
            }{" "}
          </Link>
        </VStack>
      </Box>
    </Flex>
  );
};

export default HomeScreen;
