import React from 'react';
import moment from 'moment';
import api from '../api';
import { Box, Text, Flex, Badge, Avatar, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import BetCard from './BetCard';

const calculateNextMatch = (games) => {
    let nextMatch = null;
    if(!games){
        return null;
    }
    const nextMatchs = games.filter((game) => {
        console.log(game)
        return moment(game.game.date).isAfter(moment());
    });
    if(nextMatchs.length === 0){
        return null;
    }
    nextMatch = nextMatchs[0];
    for(let i = 0; i < nextMatchs.length; i++){
        if(moment(nextMatchs[i].game.date).isBefore(moment(nextMatch.game.date))){
            nextMatch = nextMatchs[i];
        }
    }
    return nextMatch;
}


const PortfolioCard = ({ subscription, listGames = false }) => {
    const [games, setGames] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [betObj, setBet] = React.useState(null);
    const [interval, setInterval] = React.useState(null);
    const { loadBets, getPortfolioBet } = api;
    const [countDown, setCountDown] = React.useState(null);

    const unsubscribe = async (e) => {
      try {
        e.preventDefault();
        await api.unsubscribe(subscription.id);
        window.location.reload();
      } catch (err) {
        console.error(err);
      }
    };
    const getBet = async () => {
      try {
        const response = await getPortfolioBet(subscription.id);
        if (response.data && response.data.length === 0) {
          return;
        }
        setBet(response.data);
      } catch (err) {
        console.error(err);
      }
    };
  
    const getCountDown = (date) => {
      const now = moment();
      console.log(now);
      const gameDate = moment(date);
      const diff = gameDate.diff(now, "seconds");
      if(diff < 0){
        return "00:00:00";
      }
      if(diff > 24*60*60*1000){
        return gameDate.fromNow();
      }
      console.log(diff);
      const hours = Math.floor(diff / (60 * 60 ));
      const minutes = Math.floor((diff % (60 * 60 )) / (60 ));
      const seconds = Math.floor((diff % (60 )) / 1);
      return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      
    };
  
  
  
  
    React.useEffect(() => {
      const fetchPortfolio = async () => {
        try {
          const response = await loadBets(subscription.id);
          if (response.data.length === 0) {
            return;
          }
          setGames(response.data);
          getBet();
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      };
      fetchPortfolio();
    }, []);
  
    React.useEffect(() => {
      if (games && !interval && nextMatch) {
        const interval = setInterval(() => {
          const nextMatch = calculateNextMatch(games);
          if(!nextMatch){
            
            return;
          }
          console.log(nextMatch);
          setCountDown(getCountDown(nextMatch.game.date));
        }, 1000);
        setInterval(interval);
      }
      })
  if(listGames && !games){
    return null;
    }
   const nextMatch = games && calculateNextMatch(games);

  return (
   <Link to={`/bets/${subscription.id}`}> 
    <Box
      bg="gray.800" // Assuming this is your background color
      borderRadius="md"
      p={4}
      w={"80vw"}
      style={{width: "80vw !important"}} // Adjust width as needed
      mr={4} // Space between cards in the carousel
    >
      <Text color="white" fontWeight="bold" fontSize="lg">
        {subscription.name} <Button onClick={unsubscribe} size="sm" colorScheme="red" ml={2}>Unsubscribe</Button>
      </Text>
      <Flex justify="space-between" align="center" mt={2}>
        <Text color="whiteAlpha.700" fontSize="sm">Matches to bet</Text>
        <Badge colorScheme={Number(games && games.length)? "green": "red"}>{Number(games && games.length)}/{subscription.portfolio_users.game_number}</Badge>
      </Flex>
      <Flex justify="space-between" align="center" mt={2}>
        <Text color="whiteAlpha.700" fontSize="sm">Next match</Text>
        <Text color="whiteAlpha.700" fontSize="sm">{countDown}</Text>
      </Flex>
      {listGames && games && games.map((gameBet) => {
        const game = gameBet.game;
        return (
        
          <Box background={"gray.600"}

          order={moment(game.date).diff(moment(), `hours`)}
          p={5}
            borderRadius="lg" w="full" mb={5}>
          <Text color="whiteAlpha.700" fontSize="xs" mb={2} textAlign={"center"}>
            {game.competition.name} - {game.date}
          </Text>
          <Flex align="center" justify="space-between" 
          >
            <Flex align="center">
              <Avatar   mr={2} name={game.team_1.name} />
              <Text color="white" fontWeight="bold">{game.team_1.name}</Text>
            </Flex>
            <Text color="white">x</Text>
            <Flex align="center">
              <Text color="white" fontWeight="bold">{game.team_2.name}</Text>
              <Avatar src={game.team2Logo} ml={2} name={game.team_2.name} />
            </Flex>
          </Flex>
        </Box>
      )})}
        {listGames && (<>
      <Flex justify="space-between" align="center" mt={2}>
        <Text color="whiteAlpha.700" fontSize="sm">Competition</Text>
        <Text color="whiteAlpha.700" fontSize="sm">{nextMatch && nextMatch.game.competition.name}</Text>
      </Flex>
      <Box borderTop="1px" borderColor="gray.700" mt={2} pt={2}>
        <Flex justify="space-between" align="center">
          <Text color="whiteAlpha.700" fontSize="sm">Portfolio Total Cost</Text>
          <Text color="white" fontWeight="bold" fontSize="lg">$3.5</Text>
        </Flex>
      </Box></>)}
    </Box>
    </Link>
  );
};

export default PortfolioCard;
