import {
  Box,
  Tab,
  TableContainer,
  Table,
  Td,
  Tr,
  Thead,
  Th,
  Tbody,
  Card,
    CardBody,
    Heading,
    SimpleGrid,
    Button,
} from "@chakra-ui/react";
import React from "react";
import api from "../../api";

const LastGames = () => {
  const [games, setGames] = React.useState([]);
  const [loading, setLoading] = React.useState(true);



  React.useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await api.getAdminGames({not_started: true, limit : 5});
        setGames(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchGames();
  },[]);

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Game</Th>
          </Tr>
        </Thead>
        <Tbody>
          {games.map((game) => (
            <Tr key={game.id}>
              <Td>{game.team_1.name} X {game.team_2.name}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const Admin = () => {
  const [email, setEmail] = React.useState('');
  const impersonate = async (email) => {
    try {
      const response = await api.impersonate(email);
      localStorage.setItem('token', response.data.access);
      localStorage.setItem('refresh', response.data.refresh);
      window.location.href = '/dashboard';
    } catch (err) {
      console.error(err);
    }
  }
  return (
    <Box>
       <Heading as="h2" size="xl" mb={6} textAlign="center"> 
        Admin Dashboard
        </Heading>
        <SimpleGrid columns={3} spacing={10}>
      <Card>
        <CardBody>
          <LastGames />
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <h2>Impersonate</h2>
          <input type="text" placeholder="email" onChange={(e)=> setEmail(e.target.value)} />
          <Button onClick={() => impersonate(email)}>Impersonate</Button>
        </CardBody>
      </Card>
      </SimpleGrid>
    </Box>
  );
};

export default Admin;
