// screens/AccountScreen.js
import React from 'react';
import { Box, Heading, Text, VStack, Divider } from '@chakra-ui/react';
import api from '../api';

function AccountScreen() {
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState(null);
  const { getCurrentUser } = api;

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getCurrentUser();
        setUser(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  })



  return (
    <Box maxW="md" mx="auto" mt={10}>
      <Heading as="h1" size="xl" mb={6} textAlign="center">
        My Account
      </Heading>
      <VStack spacing={4} align="start">
        <Text><strong>Membership Level:</strong> Gold</Text>
        <Divider />
        <Text><strong>User #:</strong> {user.id}</Text>
        <Text><strong>PIN:</strong> 123456</Text>
        <Text><strong>First Name:</strong> Emily</Text>
        <Text><strong>Family Name:</strong> Hoskins</Text>
        <Text><strong>Birth Date:</strong> 12 November 2002</Text>
        <Text><strong>Username:</strong> Emihos0001</Text>
        <Text><strong>Mobile/Cell:</strong> +61 402 568 459</Text>
        <Text><strong>Primary Email:</strong> emily.hoskins2022@gmail.com</Text>
        <Text><strong>Recovery Email:</strong> emily_hoskins@hotmail.com</Text>
      </VStack>
    </Box>
  );
}

export default AccountScreen;
