// screens/AccountScreen.js
import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Divider,
  Button,
  IconButton,
  useDisclosure,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  HStack,

} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import api from "../api";
import { FaCaretRight, FaChevronRight } from "react-icons/fa";

const Portfolio = ({ portfolio }) => {
  const [portfolioInfo, setPortfolioInfo] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const { getPortfolio } = api;

  React.useEffect(() => {
    const fetchPortfolio = async () => {
      try {
        const response = await getPortfolio(portfolio.id);
        setPortfolioInfo(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchPortfolio();
  }, [portfolio.id]);

  return (
    <Box>
      <Divider my={2} />
      {loading ? (
        <Text>Loading...</Text>
      ) : (
        <VStack align="start">
            {!!portfolioInfo.sports.length && <Text>
                Sports: <strong>{portfolioInfo.sports.map(s=>s.name).join(", ")}</strong>
            </Text>}
            {!!portfolioInfo.competitions.length && <Text>
                Competitions: <strong>{portfolioInfo.competitions.join(", ")}</strong>
            </Text>}
            {!!portfolioInfo.teams.length && <Text>
                Teams: <strong>{portfolioInfo.teams.join(", ")}</strong>
            </Text>}
            {!!portfolioInfo.geolocations.length && <Text>
                Geolocations: <strong>{portfolioInfo.geolocations.join(", ")}</strong>
            </Text>}
                1
          <Button colorScheme="teal" size="sm" mt={2}>
            View Portfolio
          </Button>
        </VStack>
      )}
    </Box>
  );
};

const PortfolioList = ({ portfolio, reload = () => {} }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const subscribe = async () => {
    try {
      await api.subscribe(portfolio.id);
      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const meta = []
  meta.push(...portfolio.sports.map(s=>s.name))
  meta.push(...portfolio.competitions.map(c=>c.name))
  meta.push(...portfolio.teams.map(t=>t.name))
  meta.push(...portfolio.geolocations.map(g=>g.name))
  
  return (
    <Link style={{"width":"100%"}} to={`/portfolio/${portfolio.id}`}>
    <Box mb={5} w={`full`} background={"#231F20"} p={3} borderRadius={"lg"}>
    <HStack>
      <Heading as="h2" size="md">
        {portfolio.name}
      </Heading>
      <IconButton icon={<FaChevronRight />} ml={'auto'} color={'white'} background={"transparent"}/>
      
    </HStack>
    <Divider borderColor={"#231F20"} borderWidth={2} />
    <VStack align="start">
      {meta.map((m, i) => (
        <Text key={i} color={"white"}>{m}</Text>
      ))}
      </VStack>
  </Box>
  </Link>
  );
};

const PortfoliosScreen = () => {
  const [portfolios, setPortfolios] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchPortfolios = async () => {
      try {
        const response = await api.getPortfolios();

        setPortfolios(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchPortfolios();
  }, []);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box w="100%" mx="auto" mt={10}>
      <Heading as="h1" size="xl" mb={6}>
        Portfolios &nbsp; <Button colorScheme="green" as={Link} to={'/portfolios/new'} size="xs">Create Portfolio</Button>
      </Heading>


      <VStack >

        {portfolios.map((portfolio) => {
          if (portfolio.userData) {
            return null;
          }
          return (
              <PortfolioList portfolio={portfolio} />
          );
        })}
      </VStack>
      <Link to="/dashboard">
        <Button colorScheme="white" variant={'ghost'} size="sm" mb={4}>
          See my Portfolio
        </Button>
      </Link>
    </Box>
  );
};

export default PortfoliosScreen;
