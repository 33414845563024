// components/PrivateRoute.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  if (!user) {
    return navigate('/login');
  }

  return children;
};

export const AdminRoute = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  console.log('user', user);
  if (!user) {
    return navigate('/login');
  }
  if (!user.is_superuser) {
    return navigate('/dashboard');
  }


  return children;
};


export default PrivateRoute;
