import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  Text,
  TableContainer,
  FormControl,
} from "@chakra-ui/react";
import React from "react";
import api from "../api";
import { calculateBestOdds } from "../screens/BetScreen";
import MoneyInput from "./MoneyInput";

const BetModal = ({ portfolio, games, total, open, setModalOpen }) => {
  const [betValue, setBetValue] = React.useState(total);
  const [gameOdds, setGameOdds] = React.useState({});
  
  const { bet } = api;
  const toast = useToast();
  const bestOdds = calculateBestOdds(games);
  React.useEffect(() => {
    const gameOdds = {};
    games.forEach((game) => {
      gameOdds[game.game.id] = bestOdds[game.game.id].odd;
    });
    setGameOdds(gameOdds);
  },[])
  React.useEffect(() => {
    setBetValue(total);
    }, [total]);
  const changeGameOdd = (gameId, value) => {
    setGameOdds({ ...gameOdds, [gameId]: value });
  }

  const saveBet = async () => {
    try {
        const request = {
            value: betValue,
            games: games.map((game) => {
                return {
                    game: game.game.id,
                    odd: gameOdds[game.game.id],
                    value: game.value
                }
            })
        }
        const response = await bet(portfolio.id, request);
        if (response.status === 201) {
            toast({
                title: "Bet placed successfully",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
            setModalOpen(false);
        }
    } catch (err) {
        console.error(err);
        toast({
            title: "Error placing bet",
            status: "error",
            duration: 9000,
            isClosable: true,
        });
    }
}

  if(!games) return null;
  if(!portfolio) return null;
  return (
    <Modal isOpen={open} onClose={() => setModalOpen(false)}>
      <ModalOverlay />
      <ModalContent bg={'gray.600'}>
        <ModalHeader>Bet on {portfolio.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          
          <Text display={'inline'}>Im Betting<Text color={'#22DD22'} fontSize={20} fontWeight={'600'}> ${total}</Text></Text>

          <TableContainer >
            <Table colorScheme="white">
              <Tbody>
                <Tr>
                  <Th color={'white'}>Teams</Th>
                  <Th color={'white'} >Bet</Th>
                </Tr>
                {games.map((game, index) => {

                  return (
                    <Tr>
                      <Td>{game.game.team_1.name} x {game.game.team_2.name}</Td>
                      
                      <Td>
                        ${game.value}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={() => setModalOpen(false)}>
            Close
          </Button>
          <Button
            colorScheme="green"
            onClick={saveBet}
          >
            Bet
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BetModal;